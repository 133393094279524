import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import './popUp.scss';

function PopUp(args) {
  const [modal, setModal] = useState(true); // Set modal to true by default

  // Function to toggle the modal
  const toggle = () => setModal(!modal);
  // const modalStyles = {
  //   fontFamily: 'Arial, sans-serif !important', // Example font family
  // };

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} {...args}>
        <ModalHeader toggle={toggle}>
          <div className="warning-modal-title">DO NOT CONSUME ADVISORY:</div>
        </ModalHeader>
        <ModalBody>
          At Pitka Bay Resort, your safety and comfort are our top priority.
          While we’ve been addressing elevated levels of arsenic in the water,
          we’re pleased to inform you that all guest units are now equipped with
          clean, potable water. Additionally, refills are always available at
          the office to ensure you have access to safe drinking and cooking
          water throughout your stay. We look forward to welcoming you and
          ensuring a safe, relaxing experience.
          <br />
          <br />
          The Pitka team.
        </ModalBody>
      </Modal>
    </div>
  );
}

export default PopUp;
